const partnershipGreens = {
  green: "#003936",
  midGreen: "#05B084",
  lightGreen: "#B7E0CB",
};

const happyOranges = {
  orange: "#F1B472",
  darkOrange: "#EE8C2D",
  lightOrange: "#F6DECA",
};

const white = "#FFFFFF";

export {partnershipGreens, white, happyOranges};