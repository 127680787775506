import { Container } from "react-bootstrap";
import "./styles.scss";
import Hero from "../../components/Hero";
import { partnershipGreens } from "../../themes/colours";
import ContactForm from "../../components/ContactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>
          Contact the John Lewis Partnership Insights and Media team
        </title>
        <meta
          name="description"
          content="The John Lewis Partnership Insights and Media team is on hand to help you with any questions. Use the contact form and we'll come back to you."
        />
      </Helmet>
      <Hero colour={partnershipGreens.lightGreen} title="Contact us" />
      <Container className="w-100 main-container">
        <p className="body-l body-text mb-4">
        Our dedicated team is on hand to help you with any questions.
        </p>
        <p className="body-l body-text">

Not sure who to reach out to? Use the form and we'll come back to you. 

        </p>
        <ContactForm />
      </Container>
    </>
  );
};

export default Contact;
