import { Col, Row, Image } from "react-bootstrap";
import s from "./styles.module.scss";
import DownloadButton from "../Buttons/Download";

const MediaBlockComponent = ({ mediaBlock }) => {
  const { image, heading, text, opportunities, objectives, stats, link } = mediaBlock;
  return (
    <Row className={s.row}>
      <Col className={`${s.mediaBlock} ${s.imageContainer}`} lg={6}>
        <Image src={image} />
      </Col>
      <Col className={`${s.mediaBlock} ${s.textContainer}`} lg={6}>
        <div className="mb-3">
          <h2>{heading}</h2>
          <p>{text}</p>
        </div>
        {objectives && (
          <div className="mb-3">
            <p className="small-print-headline">Key objectives</p>
            <ul className={`small-print-body ${s.styledList}`}>
              {objectives.map((objective) => (
                <li>{objective}</li>
              ))}
            </ul>
          </div>
        )}
        {opportunities.points && (
          <div className="mb-3">
            <p className="small-print-headline">Media opportunities</p>
            <ul className={`small-print-body ${s.styledList}`}>
              {opportunities.points.map((opportunity) => (
                <>
                  <li>{opportunity.text}</li>
                  {opportunity.subPoints && (
                    <ul className={`${s.styledList} ${s.subpointList}`}>
                      {opportunity.subPoints.map((subpoint) => (
                        <li>{subpoint}</li>
                      ))}
                    </ul>
                  )}
                </>
              ))}
            </ul>
          </div>
        )}
        {stats && (
          <div className="mb-3">
            <p className="small-print-headline">Channel Key Stats / Audience </p>
            <ul className={`small-print-body ${s.styledList}`}>
              {stats.map((stat) => (
                <li>{stat}</li>
              ))}
            </ul>
          </div>
        )}
        <DownloadButton variant="primary" text="Find out more" downloadLink={link} />

      </Col>
    </Row>
  );
};

export default MediaBlockComponent;
