export const mediaBlocks = [
  {
    image: "/assets/photography/printed_media.png",
    heading: "Print Media",
    text: "Influence and inspire customers during their shopping journey with high impact displays and sampling opportunities that command the attention of shoppers and help drive them to your brand in-store.",
    objectives: [
      "Boost brand engagement and buzz through direct to customer activity",
      "Generate mass awareness and stand-out in the in-store environment; ideal for amplifying in store promotions and offers, generating awareness for new products, or driving purchase consideration at key seasonal moments.",
    ],
    opportunities: {
      points: [
        { text: "Waitrose Weekend" },
        { text: "Waitrose Food" },
        { text: "Recipe Cards" },
        { text: "Drinks Guide in Waitrose Food" },
        { text: "Waitrose Entertaining Brochure" },
      ],
    },
    link: "https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose+New/WAITROSE+Retail+Media+-+Print+Media+Pack+.pdf",
  },
  {
    image: "/assets/photography/digital_media.png",
    heading: "Digital Media",
    text: "Invest in online media assets to engage customers with highly visible content across waitrose.com, rapid delivery sites and our social media channels to raise awareness and drive sales for your brand.",
    objectives: [
      "Drive reach amongst hard to find customers across the web with a brand message or conversion call to action.",
      "Pair social with online media to reinforce your brand message and maximise conversions.",
      "Enhance your campaigns through a cross channel activation strategy.",
    ],
    opportunities: {
      points: [
        { text: "Dish Podcast" },
        { text: "waitrose.com & Cellar" },
        { text: "Social Media" },
        { text: "On Demand Groceries" },
        { text: "myWaitrose Personalised Offers (for more details speak to your Media manager)" },
      ],
    },
    link: "https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose+New/WAITROSE+Retail+Media+-+Digital+Media+Pack.pdf",
  },
  {
    image: "/assets/photography/experiencial.png",
    heading: "Experiential & Sampling Media",
    text: "",
    objectives: [
      "Drive product trial through our in store sampling program.",
      "Sampling provides the opportunity to hand out sample product to our customers or send samples with waitrose.com deliveries.",
      "Delivers consideration with proximity to point of purchase.",
      "Engage with customers and create theatre in stores with our experiential opportunities",
    ],
    opportunities: {
      points: [
        {
          text: "Sampling via our waitrose.com delivery service",
        },
        {
          text: "In store sampling",
        },
        {
          text: "BWS Sampling",
          subPoints: [
            "Kings Cross Wine Bar Takeover",
            "Foodhall Window Takeover",
          ],
        },
        { text: "Canary Wharf ‘Stunt Space’" },
        {
          text: "Waitrose Food & Drink Festival",
        },
      ],
    },
    link: "https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose+New/WAITROSE+Retail+Media+-+Experiential+%26+Sampling+Media+Pack.pdf",
  },
  {
    image: "/assets/photography/instore_media.png",
    heading: "Instore Media",
    text: "Influence and inspire Customers during their shopping journey with high impact displays and that command the attention of shoppers and help drive customers to your brand in-store.",
    objectives: [
      "Boost brand engagement and buzz through direct to customer activity",
      "Generate mass awareness and stand-out in the in-store environment; ideal for amplifying in store promotions and offers, generating awareness for new products, or driving purchase consideration at key seasonal moments.",
    ],
    opportunities: {
      points: [
        { text: "POS" },
        { text: "Coupon at Till" },
        { text: "Digital Out of Home" },
      ],
    },
    stats: [
      "329 store network nationwide",
      '"Supermarket display advertising is highly effective: +16% average sales uplift during campaign; +9% average sales uplift post campaign" (Source: Nielsen)"',
    ],
    link: "https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose+New/WAITROSE+Retail+Media+-+Instore+Media+Pack++(1).pdf",
  },
];
