import { Container, Col, Row } from "react-bootstrap";
import Hero from "../../../components/Hero";
import s from "./styles.module.scss";
import MediaBlockComponent from "../../../components/MediaBlock";
import { mediaBlocks } from "./mediaBlocks";
import DownloadButton from "../../../components/Buttons/Download";
const WaitrosePage = () => {
  const blocks = [
    {
      title: "Our Purpose",
      text: "To help brands reach the Waitrose customer in the most effective and engaging ways possible, delivering ROI and mutual commercial benefit.",
    },
    {
      title: "Our Commitment",
      text: "The John Lewis Partnership is renowned for its trust and reputation: we aim to provide our media clients with the same levels of service, expertise and value that our customers expect from us.",
    },
    {
      title: "Our USP",
      text: "Waitrose Media campaigns provide a unique opportunity to speak to an affluent, engaged audience at scale, across every stage of the customer journey.",
    },
  ];

  return (
    <div>
      <Hero
        title="WAITROSE"
        fullWidth="true"
        titleColor="#ffffff"
        mobileCentered="true"
        overlayImgUrl="/assets/logos/waitrose_overlay.png"
        customClass="waitrose"
      />
      <Container className={`w-100 ${s.mainContainer}`}>
        <Container>
          <Row className={s.row}>
            {blocks.map(({ title, text }) => {
              return (
                <Col className={s.introContainer}>
                  <h1>{title}</h1>
                  <p>{text}</p>
                </Col>
              );
            })}
          </Row>
          <Row className={s.row}>
            <Col className={s.introContainer}>
              <h1>Our Audience</h1>
              <p>
                75% ABC1 audience, vs 55% in wider grocery market ------ 27%
                higher basket spend per customer than grocery retailer average
                ------ 46% of Waitrose customers are AB 18% more frequent shops
                per annum than grocery retailer average ------ 945k daily
                footfall across estate ------ 432m impressions online per annum,
                369m uniques
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          {mediaBlocks.map((mediaBlock) => (
            <MediaBlockComponent mediaBlock={mediaBlock} />
          ))}
        </Container>

        <Container>
          <Row className={s.row}>
            <Col className={s.introContainer}>
              <div className="mb-3">
                <h1>2025 Deadlines</h1>
                <p>
                  Please download the PDF attachment below to view all media
                  deadlines for 2025.
                </p>
                <p>
                  Note, these are subject to change so please ensure you are
                  working from the latest version.
                </p>
              </div>
              <div>
                <DownloadButton
                  variant="primary"
                  text="Download"
                  downloadLink={"https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose+New/Waitrose+Retail+Media+Deadlines+2025.pdf"}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default WaitrosePage;
