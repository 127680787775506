import { Container, Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";

import "./styles.scss";
import CardComponent from "../../components/Card";
import { Helmet } from "react-helmet";
const Homepage = () => {
  const cards = [
    {
      title: "Media",
      link: "media",
      imgUrl:
        "/assets/photography/2x20230831_BM_RetailMedia_Waitrose_2_239.jpg",
    },
    {
      title: "Creative",
      link: "creative",
      imgUrl: "/assets/photography/2xDAY_4_SUM23_MENS_06_101.jpg",
    },
    {
      title: "Insights",
      link: "insights",
      imgUrl:
        "/assets/photography/2x2023_BM_RetailMedia_JohnLewis_Shot_1_220.jpg",
    },
  ];
  return (
    <div className="homepage">
        <Helmet>
        <title>
        John Lewis Partnership Insights and Media for retail 
        </title>
        <meta
          name="description"
          content="The John Lewis Partnership brands offer collaboration opportunities with suppliers to gain customer insights through data and access retail media advertising."
        />
      </Helmet>
      <div className="hero-homepage d-flex">
        <Container className="text-center">
          <Image
            className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
            src="/assets/Hero/you_and_us.svg"
          />
          <Image
            className="d-lg-none d-xl-block d-xl-none"
            src="/assets/Hero/you_and_us_mobile.svg"
          />
        </Container>
      </div>
      <div className="info-section">
        <Container className="w-100 main-container">
          <h3>Imagine what we can do together</h3>
          <p className="heading-m">
            Combining our unique strengths, to form stronger connections with
            customers.
          </p>
          <p className="heading-m">The link between inspiring & desiring.</p>
          <p className="heading-m">
            Using real data to create real experiences that are seen & felt.
          </p>
          <p className="heading-m">
            All thanks to a deeper understanding of customers, bringing them
            closer than ever before.
          </p>
          <p className="heading-m">JLP Insights & Media</p>
        </Container>
      </div>
      <Container className="w-100 main-container">
        <h3 className="body-text mb-4" style={{ textAlign: "unset" }}>
          Why work with the John Lewis Partnership?
        </h3>
        <p className="body-l body-text mb-4">
          As a brand partner with us, you get direct contact with some of the
          UK’s most deeply engaged shoppers.
        </p>
        <p className="body-l body-text mb-4">
          John Lewis and Waitrose, our much loved brands, have been building
          trust in our communities for decades. Our thousands of inspiring
          Partner-owners give our customers the kind of experiences that have
          made us one of the top 3 retailer brands in the UK.
        </p>
        <p className="body-l body-text mb-4">
          Work with us to create more memorable moments for our loyal customers
          – and form the kind of connections we’re known for.
        </p>
        <p className="body-l body-text mb-4">
          Make stronger connections with your customers. Understand their needs
          and harness their desires – all informed by real data, and real
          experiences.
        </p>
      </Container>
      <div className="form-background">
        <Container className="w-100 main-container">
          <h3 style={{ textAlign: "center" }}>Discover our services</h3>
          <Row>
            {cards.map((card) => {
              return (
                <Col xs={12} lg={4}>
                  <CardComponent card={card} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Homepage;
