import Hero from "../../components/Hero";
import { happyOranges } from "../../themes/colours";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./styles.scss";
const MeetTheTeamPage = () => {
  const tabs = [
    {
      title: "Insights & Media - John Lewis",
      eventKey: "johnLewis",
      teamMembers: [
        {
          name: "Anna Khan",
          jobTitle: "Media Sales Lead",
          category: "",
          email: "anna.khan@johnlewis.co.uk",
        },
        {
          name: "Louise Elliott",
          jobTitle: "Senior Media Sales Manager",
          category: ", Fashion",
          email: "louise.m.elliott@johnlewis.co.uk",
        },
        {
          name: "Kathryn Staples",
          jobTitle: "Media Sales Manager",
          category: ", Nursery and Kids",
          email: "kathryn.staples@johnlewis.co.uk",
        },
        {
          name: "Francesco Covelluzzi",
          jobTitle: "Media Sales Specialist",
          category: ", Fashion",
          email: "francesco.covelluzzi@johnlewis.co.uk",
        },
        {
          name: "Suzanna Knight",
          jobTitle: "Senior Media Sales Manager",
          category: ", Home",
          email: "suzanna.knight@johnlewis.co.uk",
        },
        {
          name: "Abbie Rigg-Milner",
          jobTitle: "Media Sales Specialist",
          category: ", Home",
          email: "abbie.rigg-milner@johnlewis.co.uk",
        },
        {
          name: "Frankie Ünlü",
          jobTitle: "Senior Media Sales Manager",
          category: ", Home",
          email: "frankie.unlu@johnlewis.co.uk",
        },
        {
          name: "Keira Hall",
          jobTitle: "Media Sales Manager",
          category: ", Beauty",
          email: "keira.hall@johnlewis.co.uk",
        },
        {
          name: "Shannon Davern",
          jobTitle: "Media Sales Manager",
          category: ", Beauty",
          email: "shannon.davern@johnlewis.co.uk",
        },

        {
          name: "Jiashing Moore",
          jobTitle: "Media Sales Specialist",
          category: ", Beauty",
          email: "jiashing.moore@johnlewis.co.uk",
        },
        {
          name: "Jason King",
          jobTitle: "Senior Media Sales Manager (Electricals)",
          category: ", Tech",
          email: "jason.king@johnlewis.co.uk",
        },
        {
          name: "Manoj Jadeja",
          jobTitle: "Senior Media Sales Manager (TV and Audio)",
          category: ", Tech",
          email: "manoj.jadeja@johnlewis.co.uk",
        },
        {
          name: "Sophie McDermott",
          jobTitle: "Senior Media Sales Manager (Computing and Android)",
          category: ", Tech",
          email: "sophie.mcdermott@johnlewis.co.uk",
        },
        {
          name: "Ryan Warrington",
          jobTitle: "Media Sales Specialist",
          category: ", Tech",
          email: "ryan.warrington@johnlewis.co.uk",
        },
      ],
    },
    {
      title: "Insights & Media - Waitrose",
      eventKey: "waitrose",
      teamMembers: [
        {
          name: "Laura Wakeling",
          jobTitle: "Retail Media Sales & Planning Lead",
          category: "",
          email: "laura.wakeling@waitrose.co.uk",
        },
        {
          name: "Adam Shinegold",
          jobTitle: "Retail Media Senior Manager",
          category: "Beer, Cider, Soft Drinks, No & Lo and RTD",
          email: "adam.shinegold@waitrose.co.uk ",
        },
        {
          name: "Angela Jones",
          jobTitle: "Retail Media Senior Manager",
          category: "Chilled Meal Solutions",
          email: "angela.jones@waitrose.co.uk",
        },
        {
          name: "Annabel Gosling",
          jobTitle: "Retail Media Manager",
          category: "Meat, Fish, Poultry, Eggs & Fruit",
          email: "annabel.gosling@waitrose.co.uk",
        },
        {
          name: "Annie Passingham",
          jobTitle: "Retail Media Manager",
          category: "Vegetables, Salad, Horti & FTG",
          email: "annie.passingham@waitrose.co.uk",
        },
        {
          name: "Frederic Fenucci",
          jobTitle: "Retail Media Senior Manager",
          category: "Third Party",
          email: "fred.fenucci@johnlewis.co.uk",
        },
        {
          name: "Katie Moore",
          jobTitle: "Retail Media Senior Manager",
          category: "Frozen, Free From & Bakery",
          email: "katie.moore@waitrose.co.uk",
        },
        {
          name: "Katie Kimberley",
          jobTitle: "Retail Media Senior Manager",
          category: "Health & Beauty, Impulse Snacks & GM",
          email: "katie.kimberley@waitrose.co.uk",
        },
        {
          name: "Katy Lawton",
          jobTitle: "Retail Media Senior Manager",
          category: "Grocery Meal Solutions & Core Grocery",
          email: "katy.lawton@waitrose.co.uk",
        },
        {
          name: "Rachel Crowe",
          jobTitle: "Retail Media Senior Manager",
          category: "Spirits & Wine",
          email: "rachel.crowe@waitrose.co.uk",
        },
        {
          name: "Sam Blanch",
          jobTitle: "Retail Media Senior Manager",
          category: "Household & Pet",
          email: "samantha.blanch@waitrose.co.uk",
        },
        {
          name: "Scott King",
          jobTitle: "Retail Media Senior Manager",
          category: "Dairy (inc Juices/Smoothies & Dairy Alts)",
          email: "scott.king@waitrose.co.uk",
        },
        {
          name: "Tash Leyens",
          jobTitle: "Retail Media Senior Manager",
          category: "Grocery Impulse (confec) & Biscuits",
          email: "natasha.x.leyens@waitrose.co.uk",
        },
        {
          name: "Kirsty Girard",
          jobTitle: "Retail Media Activations Manager",
          category: "",
          email: "kirsty.girard@waitrose.co.uk",
        },
        {
          name: "Becky Glenn",
          jobTitle: "Retail Media Activations Specialist",
          category: "waitrose.com, Cellar & Social",
          email: "becky.glenn@waitrose.co.uk",
        },
        {
          name: "Beth Knight",
          jobTitle: "Retail Media Activations Specialist",
          category: "POS & Coupon @ Till",
          email: "bethany.knight@waitrose.co.uk",
        },
        {
          name: "Brooke Bird",
          jobTitle: "Retail Media Activations Specialist",
          category: "JCD, Sampling",
          email: "brooke.bird@waitrose.co.uk",
        },
        {
          name: "Dan Green",
          jobTitle: "Retail Media Activations Specialist",
          category: "Waitrose Weekend, Competitions",
          email: "daniel.green@waitrose.co.uk",
        },
        {
          name: "Kelsey Timpson",
          jobTitle: "Retail Media Activations Specialist",
          category: "Dish, Waitrose Food, Recipe Cards",
          email: "kelsey.timpson@waitrose.co.uk",
        },
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Contact the John Lewis Partnership Insights and Media team
        </title>
        <meta
          name="description"
          content="The John Lewis Partnership Insights and Media team is on hand to help you with any questions. Use the contact form and we'll come back to you."
        />
      </Helmet>
      <div className="team-page">
        <Hero colour={happyOranges.orange} title="Getting results, together" />
        <Container className="w-100 main-container">
          <p className="body-l body-text">
            Our dedicated team is on hand to help you with any query you might
            have. Please see below to find out who you should be talking to.
          </p>
          <Tabs fill>
            {tabs.map(({ teamMembers, title, eventKey }) => {
              return (
                <Tab title={title} eventKey={eventKey}>
                  <Row className="team-row">
                    {teamMembers.map(({ name, jobTitle, category, email }) => {
                      return (
                        <Col lg={6} className="mb-4">
                          <p style={{ fontWeight: 700 }} className="body-l">
                            {name}
                          </p>
                          {eventKey === "johnLewis" && (
                            <p className="body-l">
                              {jobTitle}
                              {category}
                            </p>
                          )}
                          {eventKey === "waitrose" && (
                            <>
                              <p className="body-l">{jobTitle}</p>
                              <p className="body-l">{category}</p>
                            </>
                          )}
                          <p className="body-l">{email}</p>
                        </Col>
                      );
                    })}
                  </Row>
                </Tab>
              );
            })}
          </Tabs>
        </Container>
      </div>
    </>
  );
};

export default MeetTheTeamPage;
